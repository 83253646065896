import React from'react'

import { Container } from'react-bootstrap'
const Auth_URL = "https://accounts.spotify.com/authorize?client_id=72819a70cac64cf89db4d27987913049&response_type=code&redirect_uri=https://www.pifelife.com&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state"

export default function Login() {
    return (
        <Container className="d-flex justify-content-center align-items-center"
        style ={{ minHeight: "100vh" }}>
            <a className="btn btn-success btn-lg" href={Auth_URL}>Login With Spotify</a>
        </Container>
    )

};
/*
import React from "react"
import { Container } from "react-bootstrap"

const AUTH_URL =
  "https://accounts.spotify.com/authorize?client_id=8b945ef10ea24755b83ac50cede405a0&response_type=code&redirect_uri=http://localhost:3000&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state"

export default function Login() {
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <a className="btn btn-success btn-lg" href={AUTH_URL}>
        Login With Spotify
      </a>
    </Container>
  )
}
*/